.pdf-page {
    background-color: #04352A;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;
    box-sizing: border-box;
  }
  
  .pdf-container {
    margin-top: 40px;
    width: 90vw;
    height: 100vh;
    z-index: 50;
  }
  
  .bg-nav{
    position: fixed;
    top: 0;
    left: 0;
  }
  .back-button {
    color: #E0C491;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 18px;
    border-radius: 5px;
    border: 2px solid #04352A;
    gap:100px;
  }
  
  .back-button:hover {
    background-color: #E0C491;
    color: #04352A;
    border-color: #04352A;
  }
  