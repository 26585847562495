/*
.registration-page {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #000000;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 0 20px; 
}

.registration-container {
  margin: 0% auto;
  font-size: xx-large;
  font-family: 'Poppins', sans-serif;
}

.register-here {
  font-size: 2em;
  color: #E0C491; 
}

.rectangular-component {
  margin: 20% auto;
  background-color: #000000; 
  color: #E0C491;
  border: 2px solid #E0C491;
  padding: 20px;
  width: 100%;
  max-width: 800px; 
  margin: 20px auto;
  border-radius: 8px; 
}
.rectangular-contact-component {
  margin-top: 20% auto;
  background-color: #000000;
  color: #E0C491;
  border: 2px solid #E0C491;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  border-radius: 8px;
  margin: 20px auto;
}

.rectangular-component p {
  margin: 0;
}


.contact-us {
  margin-top: 0px;
  margin-right: 100px;
}

.contact-us h2 {
  color: #E0C491;
  font-size: 4em;
  margin-bottom: 20px;
}

.rectangular-contact-component a{
  color: #E0C491;
  text-underline-offset: 100px;
}

@media (max-width: 768px) {
  h1 {
      font-size: 1.5em;
  }

  .rectangular-component, 
  .rectangular-contact-component {
      width: 90%;
      padding: 15px;
  }

  .contact-us h2 {
      font-size: 2em;
  }
}

@media (max-width: 480px) {
  h1 {
      font-size: 1.5em;
  }

  .rectangular-component, 
  .rectangular-contact-component {
      width: 100%;
      padding: 10px;
  }

  .contact-us h2 {
      font-size: 1.5em;
  }
}
*/
.registration-page {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #04352A;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  text-align: center;
  padding: 0 20px; /* Add padding for small screens */
}

.registration-container {
  margin: 0% auto;
  font-size: xx-large;
  font-family: 'Poppins', sans-serif;
}

.register-here {
  font-size: 2em;
  color: #E0C491; /* Adjust as needed */
}

.rectangular-component {
  margin: 20% auto;
  background-color: #04352A; /* Adjust as needed */
  color: #E0C491;
  border: 2px solid #E0C491;
  padding: 20px;
  width: 100%;
  max-width: 800px; /* Adjust max-width for larger screens */
  margin: 20px auto;
  border-radius: 8px; /* Rounded corners if desired */
}

.rectangular-contact-component {
  margin-top: 20% auto;
  background-color: #04352A;
  color: #E0C491;
  border: 2px solid #E0C491;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  border-radius: 8px;
  margin: 20px auto;
}

.rectangular-component p {
  margin: 0;
}

/* Add fade-in transition */
.contact-us {
  opacity: 0; /* Initially hidden */
  transition: opacity 1s ease-in-out; 
}

.contact-us.fade-in {
  opacity: 1; /* Fully visible when scrolled */
}

.contact-us {
  margin-top: 0px;
}

.contact-us h2 {
  color: #E0C491;
  font-size: 4em;
  margin-bottom: 20px;
}

.rectangular-contact-component a {
  color: #E0C491;
  text-underline-offset: 100px;
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.4em;
  }

  .rectangular-component{
    width: 80%;
    padding: 15px;
    font-size: 20px;
  }

  .contact-us h2 {
    padding-left: 20px;
    font-size: 1.8em;
    margin-bottom: 10px;
  }

  .register-here {
    font-size: 1em;
  }

  .rectangular-contact-component{
    width: 90%;
    align-items: center;
  }

  .contact-us {
    opacity: 0; /* Initially hidden */
    transition: opacity 1s ease-in-out; 
    justify-content: center;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.4em;
  }

  .rectangular-component{
    width: 80%;
    padding: 15px;
    font-size: 20px;
  }

  .contact-us h2 {
    padding-left: 20px;
    font-size: 1.8em;
    margin-bottom: 10px;
  }

  .register-here {
    font-size: 1.2em;
  }

  .rectangular-contact-component{
    width: 90%;
    align-items: center;
  }

  .contact-us {
    opacity: 0; /* Initially hidden */
    transition: opacity 1s ease-in-out;
    justify-content: center; 
  }
}