.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #04352A;
    padding: 10px 20px;
    padding-left: 30px; 
    position: fixed; 
    z-index: 100;
    width: 100%;
    height:8vh;
  }
  
  .scroll-progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px; 
    background-color: #E0C491; 
    width: 0;
    transition: width 0.25s ease-out; 
  }
  
  .navbar-menu {
    list-style: none;
    display: flex;
    gap: 70px;
    margin: 0;
    padding-left: 550px;
    flex: 1; /* Allow the menu to take up remaining space */
  }
  
  .navbar-menu li {
    display: inline;
  }
  
  .navbar-menu a {
    color: #E0C491;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    transition: color 0.3s ease;
  }
  
  .navbar-menu a:hover {
    color: #E0C491;
  }
  
  .navbar-icons {
    display: flex;
    gap: 15px;
    padding-right: 40px
  }
  
  .navbar-icons a {
    color: #E0C491;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .navbar-icons a:hover {
    color: #ffffff;
  }
  
  /* Adjust spacing between elements */
  .navbar > * {
    margin-right: auto; /* Distribute space evenly */
  }
  
  .navbar-menu {
    margin-right: auto; 
  }
  
  @media (max-width: 1024px) {
    .navbar-menu {
      padding-left: 200px; 
      gap: 50px;
    }
  }
  
  @media (max-width: 768px) {
    .navbar {
      padding: 10px;
      padding-left: 20px;
      overflow-x: scroll; 
      height: 4vh;
      position: fixed;
    }
  
    .navbar-menu {
      padding-left: 100px; 
      gap: 30px;
    }

    .navbar li{
      display: inline-block;
      width: fit-content;
      white-space: nowrap;
    }
  
    .navbar-icons {
      padding-right: 20px;
      padding-left:20px;
    }

    .navbar-menu a {
      font-size: 12px; 

    }

    .navbar-icons a {
      font-size: 12px; /* Further reduce icon size */
    }
  }
  
  @media (max-width: 480px) {
    .navbar {
      padding: 10px;
      padding-left: 00px;
      overflow-x: scroll;
      position: fixed;
    }
  
    .navbar-menu {
      padding-left: 50px; 
      gap: 20px;
    }
  
    .navbar-icons {
      padding-right: 10px;
    }

    .navbar-menu a {
      font-size: 12px; /* Smallest font size */
    }
    
    .navbar-icons a {
      font-size: 18px; /* Smallest icon size */
    }
  }