.hidden {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.visible {
  opacity: 1;
}

html, body {
  height: 100%; 
  margin: 0;
}

.welcome-page {
  position: relative; 
  display: flex;
  flex-direction: column;
  background-color: #04352A; 
  min-height: 100vh; 
  overflow: hidden; 
}

@keyframes gradualFade {
  0% {
    opacity: 0;
  }
  1% {
    opacity: 0.025;
  }
  2% {
    opacity: 0.05;
  }
  3% {
    opacity: 0.075;
  }
  4% {
    opacity: 0.1;
  }
  5% {
    opacity: 0.125;
  }
  6% {
    opacity: 0.15;
  }
  7% {
    opacity: 0.175;
  }
  8% {
    opacity: 0.2;
  }
  9% {
    opacity: 0.225;
  }
  10% {
    opacity: 0.25;
  }
  11% {
    opacity: 0.275;
  }
  12% {
    opacity: 0.3;
  }
  13% {
    opacity: 0.325;
  }
  14% {
    opacity: 0.35;
  }
  15% {
    opacity: 0.375;
  }
  16% {
    opacity: 0.4;
  }
  17% {
    opacity: 0.425;
  }
  18% {
    opacity: 0.45;
  }
  19% {
    opacity: 0.475;
  }
  20% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.525;
  }
  22% {
    opacity: 0.55;
  }
  23% {
    opacity: 0.575;
  }
  24% {
    opacity: 0.6;
  }
  25% {
    opacity: 0.625;
  }
  26% {
    opacity: 0.65;
  }
  27% {
    opacity: 0.675;
  }
  28% {
    opacity: 0.7;
  }
  29% {
    opacity: 0.725;
  }
  30% {
    opacity: 0.75;
  }
  31% {
    opacity: 0.775;
  }
  32% {
    opacity: 0.8;
  }
  33% {
    opacity: 0.825;
  }
  34% {
    opacity: 0.85;
  }
  35% {
    opacity: 0.875;
  }
  36% {
    opacity: 0.9;
  }
  37% {
    opacity: 0.925;
  }
  38% {
    opacity: 0.95;
  }
  39% {
    opacity: 0.975;
  }
  40% {
    opacity: 1;
  }
  
  61% {
    opacity: 0.975;
  }
  62% {
    opacity: 0.95;
  }
  63% {
    opacity: 0.925;
  }
  64% {
    opacity: 0.9;
  }
  65% {
    opacity: 0.875;
  }
  66% {
    opacity: 0.85;
  }
  67% {
    opacity: 0.825;
  }
  68% {
    opacity: 0.8;
  }
  69% {
    opacity: 0.775;
  }
  70% {
    opacity: 0.75;
  }
  71% {
    opacity: 0.725;
  }
  72% {
    opacity: 0.7;
  }
  73% {
    opacity: 0.675;
  }
  74% {
    opacity: 0.65;
  }
  75% {
    opacity: 0.625;
  }
  76% {
    opacity: 0.6;
  }
  77% {
    opacity: 0.575;
  }
  78% {
    opacity: 0.55;
  }
  79% {
    opacity: 0.525;
  }
  80% {
    opacity: 0.5;
  }
  81% {
    opacity: 0.475;
  }
  82% {
    opacity: 0.45;
  }
  83% {
    opacity: 0.425;
  }
  84% {
    opacity: 0.4;
  }
  85% {
    opacity: 0.375;
  }
  86% {
    opacity: 0.35;
  }
  87% {
    opacity: 0.325;
  }
  88% {
    opacity: 0.3;
  }
  89% {
    opacity: 0.275;
  }
  90% {
    opacity: 0.25;
  }
  91% {
    opacity: 0.225;
  }
  92% {
    opacity: 0.2;
  }
  93% {
    opacity: 0.175;
  }
  94% {
    opacity: 0.15;
  }
  95% {
    opacity: 0.125;
  }
  96% {
    opacity: 0.1;
  }
  97% {
    opacity: 0.075;
  }
  98% {
    opacity: 0.05;
  }
  99% {
    opacity: 0.025;
  }
  100% {
    opacity: 0;
  }
}


.welcome-page::before {
  content: ""; 
  position: fixed; 
  top: 50%; 
  left: 50%; 
  width: 50%; 
  height: 50%; 
  background-image: url('../assets/images/PeConLogoCrayolaGold_ResNew.svg'); 
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(1px); 
  z-index: 0; 
  transform: translate(-50%, -50%); 
  opacity: 0; /* Start with invisible */
  animation: gradualFade 3s forwards; /* Apply the fade-in and fade-out animation */
  animation-delay: 1s; /* Delay the start of the animation by 3 seconds */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content {
  position: relative; 
  z-index: 1; 
  margin-left: 100px;
  margin-top: 100px;
  padding: 20px;
  transition: margin-left 0.3s ease-in-out;
  color: #E0C491;
  font: 'https://fonts.googleapis.com/css?family=Poppins';
  align-items: center;
  opacity: 0; /* Start hidden */
  font-weight: 200;
  font-size:200%;
  animation: fadeIn 2s forwards;
  animation-delay: 4s;
  line-height: 25%;
}

.content p{
  font:"https://fonts.googleapis.com/css?family=Poppins";
  font-size: 1.4em;
  font-weight: bold;
}

.speeches{
  opacity: 0;
  animation: fadeIn 2s forwards;
  animation-delay: 6s;
  z-index: 2;
}

@keyframes fadeInW {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.wp-navbar{
  opacity: 0;
  animation: fadeInW 2s forwards;
  animation-delay: 4s;
  z-index: 2;
}
.welcome{
  margin: 20px auto;
  overflow: hidden;
  width: 50%;
  max-width: 1200px;
  opacity: 1;
  z-index: 2;
  font-family:'Poppins', sans-serif;
  font-size: 4em; 
  font-weight: bold; 
  text-align: center;
  color:#E0C491;
}



@keyframes fadeInWB {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rectangular-component-wp{
  margin-top: 20% auto;
  background-color: #04352A;
  color: #E0C491;
  border: 2px solid #E0C491;
  padding: 20px;
  width: 100%;
  max-width:250px;
  border-radius: 8px;
  margin: 20px auto;
  opacity: 0;
  animation: fadeInWB 2s forwards;
  animation-delay: 4s;
  z-index: 0;
  align-items: center;
}

.rectangular-component-wp p {
  margin: 0;
}

.spaceeee{
  padding-bottom: 800px;
}

@media screen and (max-width: 768px) {
  .welcome-page::before {
    content: ""; 
    position: fixed; 
    top: 50%; 
    left: 50%; 
    width: 50%; 
    height: 50%; 
    background-image: url('../assets/images/PeConLogoCrayolaGold_ResNew.svg'); 
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(1px); 
    z-index: 0; 
    transform: translate(-50%, -50%); 
    opacity: 0; /* Start with invisible */
    animation: gradualFade 3s forwards; /* Apply the fade-in and fade-out animation */
    animation-delay: 1s; /* Delay the start of the animation by 3 seconds */
  }
  .content {
    margin-left: 5px;
    padding-top: 0px; 
    padding-bottom: 0px;
    font-size:75%;
    line-height: 30px;
    text-align: center;
  }

  .content p{
    padding-top: 0px;
    line-height: 10px;
  }

  .rectangular-component-wp {    
    width: 90%;
    padding: 15px;
  }

  .spaceeee{
    padding-bottom: 0px;
  }
}

@keyframes fadeInCB {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*
.comm-box {
  background-color: #04352A; 
  border: 2px solid #E0C491; 
  color: #E0C491; 
  border-radius: 10px; 
  width: 300px; 
  height: 200px; 
  margin: 10px; 
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: 2em;
  opacity: 0;
  animation: fadeInCB 1s forwards;
  animation-delay: 5s;
}

.comm-box-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  gap: 20px; 
}

@media screen and (max-width: 768px) {
  .comm-box {
    width: 150px; 
    height: 100px; 
    padding: 0; 
    box-sizing: border-box; 
    margin: 0px;
    display: flex; 
    align-items: center; 
    justify-content: center; 
    font-size: 1.5em;
  }

  .comm-box-container {
    display: flex; 
    flex-wrap: wrap; 
    gap: 10px; 
    justify-content: center;
  }
}
*/

.comm-box-container {
  position: relative;
  width: 300px; /* Width of the visible area */
  height: 200px; /* Height of the visible area */
  overflow: hidden; /* Hide overflowing content */
  display: flex;
  justify-content: center; 
  align-items: center; /* Center vertically */
  margin: auto;
  padding-bottom: 2%;
}

/* Boxes */
.comm-box {
  position: absolute;
  width: 250px; /* Width of each box */
  height: 100px; /* Height of each box */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #04352A; /* Background color */
  border: 2px solid #E0C491; /* Border color */
  border-radius: 8px; /* Curved edges */
  color: #E0C491; /* Text color */
  font-size: 3em; /* Text size */
  opacity: 0; /* Initially hidden */
  animation: fadeInOut 16s infinite; /* Animation for fading in and out */
  animation-delay: 4s; /* Delay to start the animation from the 6th second */
  
}

/* Keyframes for fading boxes in and out */
@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  10% {
    opacity: 1;
    transform: translateX(0);
  }
  11% {
    opacity: 0;
  }
  40% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}

/* Animation delay for each box to create the looping effect */
.comm-box:nth-child(1) {
  animation-delay: 6s;
}
.comm-box:nth-child(2) {
  animation-delay: 8s;
}
.comm-box:nth-child(3) {
  animation-delay: 10s;
}
.comm-box:nth-child(4) {
  animation-delay: 12s;
}
.comm-box:nth-child(5) {
  animation-delay: 14s;
}
.comm-box:nth-child(6) {
  animation-delay: 16s;
}
.comm-box:nth-child(7) {
  animation-delay: 18s;
}
.comm-box:nth-child(8) {
  animation-delay: 20s;
}

@keyframes fadeInComm {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.comm-wp-ani {
  color: #E0C491;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  justify-content: center auto;
  padding: 50px;
  font-size: 3em;
  text-underline-offset: 0;
  
  opacity: 0; /* Initially hidden */
  animation: fadeInComm 2s ease-in forwards; /* Fade-in animation */
  animation-delay: 6s;
}

@media screen and (max-width: 768px) {
  .comm-box-container {
    width: 200px; /* Width of the visible area on mobile */
    height: 200px; /* Height of the visible area on mobile */
    padding-bottom: 50px;
    padding-left: 10px;
  }

  .comm-box {
    font-size: 1.2em; /* Adjust text size for mobile */
    width: 150px;
  }
  
}

@media screen and (max-width: 376px) {
  .comm-box-container {
    width: 175px; /* Width of the visible area on mobile */
    height: 125px; /* Height of the visible area on mobile */
    padding-bottom: 50px;
    padding-left: 10px;
  }

  .comm-box {
    font-size: 1em; /* Adjust text size for mobile */
    width: 125px;
  }

  .comm-wp-ani{
    padding-left: 12.5%;
    justify-content: center;
    font-size: 1.5em;
    font-weight: bold;
  }
  
}

@keyframes fadeInsns {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.some-new-shit {
  text-align: center;
  justify-content: center;
  font-size: 2em;
  color: #E0C491;
  text-decoration: none;
  display: column; /* Change to flex for horizontal alignment */
  list-style-type: none; /* Remove bullet points */
  opacity: 0;
  padding-left: 0;
  animation: fadeInsns 2s ease-in forwards;
  animation-delay: 6s;
}

.some-new-shit li {
  margin: 0 15px; /* Space between list items */
}

.some-new-shit a {
  color: #E0C491;
  text-decoration: none; /* Remove underline from links */
}

@media (max-width: 768px) {
  .some-new-shit {
      font-size: 1em; /* Adjust font size for smaller screens */
      flex-direction: column; /* Stack the items vertically on mobile */
  }

  .some-new-shit li {
      margin: 10px 0; /* Space between list items in vertical layout */
  }
}
