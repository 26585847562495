.about-us-container {
  background-color: #04352A;
  min-height: 100vh;
  display: flex;
  flex-direction: column; /* Ensures items are stacked vertically */
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.aup-navbar {
  width: 100%;
  position: sticky;
  top: 0; /* Keeps the Navbar at the top of the page */
  z-index: 10;
}

.slideshow-container {
  width: 100%; /* Adjust the size of the slideshow */
  height: 100vh; /* Adjust the height as needed */
  position: relative;
  overflow: hidden;
  margin-top: 75px; /* Space between Navbar and slideshow */
  opacity: 0.8;
}

.slideshow-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
}

.text-box {
  display: flex;
  flex-direction: column;
  width: 60%; /* Spans 60% of the container */
  background-color: #04352A; /* Optional: background color */
  padding: 20px; /* Added padding for spacing */
  margin-bottom: 30px; /* Added margin for spacing between text boxes */
}

/* Styling for the heading */
.heading {
  font-size: 3em;
  color: #e0c491;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  padding-bottom: 15px; /* Space between heading and paragraph */
  border-bottom: 2px solid #e0c491; /* Adds a line below heading */
}

/* Styling for the paragraph text */
.text-half {
  font-size: 1.5em;
  line-height: 1.6em; /* Improves readability */
  color: #e0c491;
}

.typewriter-cursor {
  font-weight: bold;
  color: #e0c491;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 0; }
  100% { opacity: 0; }
}

@keyframes fadeIn{
  0% {opacity: 0}
  100% {opacity: 1}
}

.ani{
  animation: 6s ease-in fadeIn;
}
/* Responsive adjustments for tablet and smaller devices */
@media (max-width: 768px) {
  .slideshow-container {
    width: 100%; /* Full width on smaller screens */
    height: 40vh;
    margin-top: 55px;
  }

  .text-box {
    flex-direction: column; /* Stack text halves vertically on small screens */
    width: 90%; /* Adjust width for smaller screens */
  }

  .text-half {
    width: 100%; /* Full width for each half on small screens */
    padding: 0px;
    font-size: 1.2em;
  }
  
  .heading {
    font-size: 2.5em; /* Smaller font for heading on smaller screens */
  }
}

/* Responsive adjustments for mobile devices */
@media (max-width: 480px) {
  .slideshow-container {
    width: 100%;
    height: 30vh;
    margin-top: 55px;
  }

  .text-box {
    flex-direction: column; /* Stack text halves vertically on mobile */
    width: 90%; /* Adjust width for mobile */
  }

  .text-half {
    width: 100%; /* Full width for each half on small screens */
    padding: 0px;
    font-size: 1em;
  }

  .heading {
    font-size: 2em; /* Smaller font for heading on mobile devices */
  }
}










.auimage-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.auimage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 240px; /* Adjust image box width */
  height: 320px; /* Adjust image box height */
  background-color: #04352A;
}

.person-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px; /* Optional: rounded edges for the image */
  border: 2px solid #E0C491; /* Box around the image only */
}

.auimage-title {
  font-size: 2em;
  color: #E0C491;
  margin-bottom: 10px;
}

.person-name {
  font-size: 1.5em;
  color: #E0C491;
  margin-top: 10px;
}
@media (max-width: 768px) 
{
  .auimage-box {
    width: 250px;  /* Reduce image box size */
    height: 350px;
  }

  .auimage-title, .person-name {
    font-size: 1.3em; /* Adjust title and name font size */
  }
}

/* For mobile phones */
@media (max-width: 480px) {
  .auimage-box {
    width: 200px;  /* Further reduce image box size */
    height: 300px;
  }

  .auimage-title, .person-name {
    font-size: 1.2em; /* Further reduce title and name font size */
  }
}




.two-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
  gap: 400px;
}

.two-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 240px; /* Adjust image box width */
  height: 320px; /* Adjust image box height */
  background-color: #04352A;
  margin: 10px; /* Spacing between the boxes */
}

.new-person-image {
  width: 240px;
  height: 320px;
  object-fit: cover;
  border-radius: 10px; /* Optional: rounded edges for the image */
  border: 2px solid #E0C491; /* Box around the image only */

}

.two-image-title {
  font-size: 2em;
  color: #E0C491;
  margin-bottom: 20px;
  text-align: center;
  white-space: nowrap;
}

.new-person-name {
  font-size: 1.2em;
  color: #E0C491;
  margin-top: 10px;
  text-align: center;
}

/* Media Queries */

/* For mobile phones */
@media (max-width: 768px) {
  .two-image-container {
    flex-direction: column; /* Stack the boxes vertically */
    gap: 75px;
    margin-top: 0px;
  }

  .two-image-box {
    width: 250px;  /* Reduce box size for smaller screens */
    height: 350px;
  }

  .two-image-title, .new-person-name {
    font-size: 1.3em; /* Adjust title and name font size for mobile */
  }
}

/* For very small devices */
@media (max-width: 480px) {
  .two-image-container {
    flex-direction: column; /* Stack the boxes vertically */
    gap: 75px;
  }

  .two-image-box {
    width: 200px;  /* Further reduce box size */
    height: 300px;
  }

  .two-image-title, .new-person-name {
    font-size: 1.2em; /* Further reduce title and name font size */
  }
}




.team-image-container {
  display: flex;
  justify-content: space-between; /* Aligns items with space in between */
  margin: 80px 0; /* Margin above and below */
  gap: 400px;
}

.team-image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px; /* Adjust width for landscape images */
  background-color: #04352A; /* Background color for the box */
}

.team-image {
  width: 400px; /* Full width for the landscape image */
  height: 300px;
  object-fit: cover;
  border: 2px solid #E0C491; /* Box around the image */
}

.team-image-title {
  font-size: 2em;
  font-weight: bold;
  color: #E0C491;
  margin-bottom: 10px;
  text-align: center;
  white-space: nowrap; /* Prevents the title from wrapping */
  overflow: hidden; /* Hides overflow content */
  text-overflow: ellipsis; /* Adds "..." if text overflows */
}

.team-member-name {
  font-size: 1.2em;
  color: #E0C491;
  margin-top: 10px;
}

.octext{
  color: #E0C491; 
  font-size: 4em; 
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .team-image-container {
    flex-direction: column; /* Stack images vertically on smaller screens */
    align-items: center; /* Center the images */
    gap: 50px;
  }
  .team-image-box {
    margin-bottom: 20px; /* Space between stacked images */
  }
  .team-image{
    width: 80%;
  }
  .octext{
    font-size: 2em;
    align-self: center;
    justify-content: center;
    padding-left: 35px;
  }
}

