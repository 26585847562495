/* Base styles */
.committees-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.color-box {
  display: flex;
  width: 100vw;
  height: 100vh; /* Full viewport height */
  
}

.green-box {
  background-color: #04352A;
  flex: 0 1 70%; /* 65% of the width */
  display: flex;
  align-items: auto;
  padding-top: 40px;
  justify-content: auto center;
  color: #E0C491;
  padding-top:100px;
  padding-bottom: 100px;
}

.yellow-box {
  background-color: #E0C491;
  flex: 0 1 35%; /* 35% of the width */
  display: flex;
  align-items: center;
  padding-top: 150px;
  justify-content: center;
  fill: #04352A;
}

.text-container{
  padding-left: 8%;
  font-family: 'Poppins', sans-serif;
  font-size: 6em;
}

.UNSC-comm{
  padding-left:0px;
}

.agenda{
  font-size: 0.5em;
  font-family: 'Poppins', sans-serif;
  align-items: right;
}

.actual-agenda{
  font-size: 0.5em;
  width: 80%;
}

.UNODC-logo{
  padding-bottom: 100px;
}
/* Adjustments for mobile view */
@media (max-width: 768px) {
  .color-box {
      flex-direction: column;
      height: auto; /* Adjust height for mobile */
  }

  .green-box,
  .yellow-box {
      width: 100%;
      margin-right: 0px;
  }
  .yellow-box{
    order: 1;
  }

  .green-box{
    order: 2;
  }
  .text-container{
    font-size: 4em;
    text-align: center;
    padding-left: 0%;
  }

  .agenda{
    align-items: center;
    font-size: 0.3em;
    text-align:center;

  }
  .actual-agenda{
    font-size: 0.5em;
    padding-bottom: 20px;
    text-align: center;
    width: 80%;
    padding-left: 8%;
  }

  .green-box,
  .yellow-box {
      width: 100%;
      margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .color-box {
      flex-direction: column;
      height: auto; /* Adjust height for mobile */
  }

  .text-container{
    font-size: 4em;
    text-align: center;
  }

  .agenda{
    align-items: center;
    font-size: 0.3em;
    text-align: center;

  }
  .actual-agenda{
    align-items: center;
    font-size: 0.5em;
    padding-bottom: 30px;
    text-align: center;
  }

  .green-box,
  .yellow-box {
      width: 100%;
      height:100%;
      margin-right: 0px;
  }
}

/* Existing styles */
.image-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  padding-top: 100px;
  
}

.image-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  border: 2px solid #E0C491;
  border-radius: 10px;
  width: 150px; /* Adjust size as needed */
  height: 175px; /* Adjust size as needed */
}

.image-text {
  text-align: center;
  z-index: 10;
}

.image-name {
  font-size: 1.5em; 
  margin-bottom: 70px;
  margin-left:70px;
  margin-right:70px;
}

.image-title {
  font-size: 1em; /* Smaller font size for title */
  margin: -70px;
  padding-bottom: 50px;
}

.comm-alignment{
  text-align: center;
  
}
.DISEC-align{
  padding-left: 120px;
}


@media (max-width: 768px) {
  .image-container {
    flex-direction: column;
    align-items: center;
    justify-content: auto center; 
    padding: 10px; 
    padding-top: -50px;
    padding-bottom: 30px;
  }

  .image-item {
    margin-bottom: 20px; /* Add spacing between images */
  }

  .image {
    width: 120px; /* Adjust size for smaller screens */
    height: 120px; /* Adjust size for smaller screens */
  }

  .image-name {
    font-size: 1.2em; /* Adjust font size for smaller screens */
    padding-top: 10px;
    margin-bottom: 5px; 
  }

  .image-title {
    font-size: 0.9em; /* Adjust font size for smaller screens */
    margin: 0;
  }
}

@media (max-width: 468px) {
  .image-container {
    flex-direction: column;
    align-items: center !important;
    justify-content: center !important; 
    padding: 10px; 
    padding-top: -50px;
    padding-bottom: 30px;
  }

  .image-item {
    margin-bottom: 20px; /* Add spacing between images */
  }

  .image {
    width: 120px; /* Adjust size for smaller screens */
    height: 120px; /* Adjust size for smaller screens */
    margin-top: 20px;
    margin-bottom: -60px;
  
  }

  .image-name {
    font-size: 1.2em; /* Adjust font size for smaller screens */
    padding-top: 20px;
    margin-top: 50px; 
  }


  .image-title {
    font-size: 0.9em; /* Adjust font size for smaller screens */
  }

  .comm-alignment{
    text-align: auto;
    padding-left: 35px;
    
  }

  .DISEC-align{
    padding-left: 10px;
  }

  .IPC-align{
    margin-right: 100px;
  }
}
