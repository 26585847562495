
html, body {
  height: 100%; 
  margin: 0;
} 

.countdown {
  position: fixed; 
  right: -20px; 
  top: 96%; 
  transform: translateY(-50%); 
  background-color: #04352A; 
  padding: 20px;
  padding-bottom: 40px;
  border-radius: 8px; /* Optional: rounded corners */
  z-index: 1; /* Ensure it's above other content */
  color: #E0C491; /* Text color */
  font-size: 16px; /* Default font size */
  font-weight: bold; /* Optional: bold text */
  max-width: 100%; /* Allow width to adjust based on screen size */
  width: 100%; /* Set width to auto to fit the content */
  white-space: nowrap; /* Prevent wrapping of content */
  display: flex;
  justify-content: center;
  text-align: center;
}


#countdown-timer {
  display: flex;
  flex-direction: row; 
  align-items: center; 
  justify-content: center; 
  
}

.countdown-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 30px; 
}

.countdown-number {
  font-size: 24px; /* Adjust number size as needed */
}

.countdown-label {
  font-size: 14px; /* Adjust label size as needed */
}

/* Responsive styles for tablets and phones */
@media screen and (max-width: 768px) {
  .countdown {
    font-size: 10px; /* Adjust font size for smaller screens */
    padding: 15px;
    max-width: 100%; /* Adjust width for smaller screens */
    text-align: center;
    right: -10px;
  }

  .countdown-number {
    font-size: 14px; /* Adjust number size for smaller screens */
  }

  .countdown-label {
    font-size: 7px; /* Adjust label size for smaller screens */
  }

  .countdown-text {
    font-size: 8px; /* Adjust as needed */
    margin-right: 10px;
    padding-left: 12px;
    padding-right: 30px;
  }

  .countdown-item{
    margin: 0 10px; 

  }
}

/* Further adjustments for very small screens */
@media screen and (max-width: 480px) {
  .countdown {
    right: -10px;
    font-size: 12px; /* Further reduce font size */
    padding: 10px;
    padding-bottom: 40px;
    max-width: 100%; /* Further adjust width */
  }

  .countdown-number {
    font-size: 14px; /* Further reduce number size */
  }

  .countdown-text {
    font-size: 14px; 
    margin-left: 1px;
  }
  .countdown-label {
    font-size: 7px; /* Further reduce label size */
  }

  .countdown-item{
    margin: 0 8px; 
  }
}
