.resources-page {
    padding: 100px;
    background-color: #04352A;
    color: #E0C491;
    font-family: Arial, sans-serif; /* Font style */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 40px);
    overflow: hidden;
  }
  
  .resources-heading {
    font-size: 48px;
    color: #E0C491;
    margin-bottom: 20px;
  }
  

  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 20px;
    width: calc(100vw - 40px);
    height: calc(100vh - 40px - 48px - 20px);
    z-index: 10;
  }
  
  .resource-button {
    background-color: #04352A;
    color: #E0C491;
    border: 2px solid #E0C491;
    border-radius: 20px;
    font-size: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .resource-button:hover {
    background-color: #E0C491;
    color: #04352A;
  }

  
  
  @media (max-width: 1024px) {
    .resources-page {
      padding: 40px;
    }
  
    .button-container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, 1fr);
      gap: 15px;
    }
  
    .resources-heading {
      font-size: 40px;
    }
  
    .resource-button {
      font-size: 26px;
    }
  }
  
  @media (max-width: 768px) {
    .resources-page {
      padding: 40px;
    }
  
    .resources-heading {
      font-size: 32px;
    }
  
    .button-container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, 1fr);
      gap: 15px;
    }
  
    .resource-button {
      font-size: 24px;
    }
  }
  
  @media (max-width: 480px) {
    .resources-page {
      padding: 40px;
    }
  
    .resources-heading {
      font-size: 28px;
      text-align: center;
    }
  
    .button-container {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(8, 1fr);
      gap: 10px;
    }
  
    .resource-button {
      font-size: 20px;
    }
  }