.coming-soon-page {
  background-color: #04352A;
  color: #E0C491;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  margin: 0;
  position: relative; 
}

.coming-soon-page-navbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1; /* Ensure the navbar is on top */
}

.csp-h1 {
  font-size: 5em;
  font-family: 'Poppins', sans-serif;
  z-index: 0; /* Ensure the text is behind the navbar */
  margin: 0; /* Remove any default margin */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the text vertically and horizontally */
}

/* Media Queries */
@media (max-width: 768px) {
  h1 {
    font-size: 2.5em; /* Slightly reduce font size on smaller screens */
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 2em; /* Further reduce font size on very small screens */
  }
}
